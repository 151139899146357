import './body.css';
import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import '@ant-design/compatible';
import { ApolloProvider } from '@apollo/react-hooks';
import { Layout } from 'antd';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import client from './graphql/client';
import Header from './components/Header';
import { updateToken } from './plugins/keycloak-util'

// const API_URL = 'http://cubejs-api.gateway.miccedu.local';
const API_URL = 'https://olap-backend.docker.miccedu.ru';
// const API_URL = 'http://localhost:4000';

// const CUBEJS_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTk1MTY0NzIsImV4cCI6MTY1OTYwMjg3Mn0.sbPpvJEaVYZ2UnnQQNmYscA5ulU_3I2q3ryhKVD5oOU';

const AppLayout = ({ children }) => (
    <Layout
        style={{
            height: '100%',
            maxWidth: '100%'
        }}
    >
        <Header />
        <Layout.Content>{children}</Layout.Content>
    </Layout>
);

let timer = null;

const App = ({ children }) => {
    const [CubeJSApi, setCubeJSApi] = useState(undefined);

    useEffect(() => {
        const updAPICredentials = async () => {
            await updateAPICredentials();
        }
        updAPICredentials();
    }, []);

    if (timer) clearInterval(timer);

    timer = setInterval(async () => {
        await updateAPICredentials()
    }, 10000)

    async function updateAPICredentials() {
        await updateToken()

        const reactToken = localStorage.getItem('react-token');
        const cubejsApi = cubejs(`Bearer ${reactToken}`, {
            apiUrl: `${API_URL}/cubejs-api/v1`
        });
        setCubeJSApi(cubejsApi);
    }

    if (CubeJSApi) {
        return (
            <CubeProvider cubejsApi={CubeJSApi}>
                <ApolloProvider client={client}>
                    <AppLayout>{children}</AppLayout>
                </ApolloProvider>
            </CubeProvider>
        );
    } else {
        //TODO: Заменить на нормальный лоадер
        return <div>Загрузка...</div>;
    }
};

export default App;
