import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, Table, notification, Row, Col, Popconfirm } from 'antd';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axiosInstance from '../plugins/axiosInstance';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const Context = React.createContext({
  name: 'Default',
});

const HistoryPage = () => {
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [historyDataSource, setHistoryDataSource] = useState([])
  const [hPage, setHPage] = useState(1)
  const [hPageSize, setHPageSize] = useState(10)
  const [hTotal, setHTotal] = useState(0)

  const [loadingSaved, setLoadingSaved] = useState(false);
  const [savedDataSource, setSavedDataSource] = useState([])
  const [sPage, setSPage] = useState(1)
  const [sPageSize, setSPageSize] = useState(10)
  const [sTotal, setSTotal] = useState(0)

  const [api, contextHolder] = notification.useNotification();

  const openErrorNotification = (placement, text) => {
    api.error({
      message: `Ошибка`,
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      placement,
    });
  };

  const openSuccessNotification = (placement, text) => {
    api.success({
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      placement,
    });
  };


  useEffect(() => {
    requestHistoryQueries();
    requestSavedQueries();
  }, [])

  const historyColumns = [
    {
      key: "1",
      title: "ID",
      width: '5%',
      dataIndex: "id",
      sorter: (first, second) => {
        return first.id < second.id
      }
    },
    {
      key: "2",
      title: (
        <Typography.Text>Наименование куба</Typography.Text>
      ),
      dataIndex: "cubeTitle",
    },
    {
      key: "3",
      title: "Последнее выполнение",
      dataIndex: "dateadd",
      render: (item) => {
        return (
          <>
            {moment(item.dateadd).format('DD  MMMM YYYY HH:mm')}
          </>
        )
      }
    },
    {
      key: "4",
      title: "",
      render: (item) => {
        return (
          <>
            <Link to={`/?historyQueryData=${item.queryData}`}>
              <Button icon={<ArrowRightOutlined />} />
            </Link>
          </>
        )
      }
    },
  ]

  const savedColumns = [
    {
      key: "1",
      title: "ID",
      width: '5%',
      dataIndex: "id",
      sorter: (first, second) => {
        return first.id < second.id
      }
    },
    {
      key: "2",
      title: (
        <Typography.Text>Наименование запроса</Typography.Text>
      ),
      dataIndex: "queryName",
    },
    {
      key: "3",
      title: (
        <Typography.Text>Наименование куба</Typography.Text>
      ),
      dataIndex: "cubeTitle",
    },
    {
      key: "4",
      title: "",
      align: 'center',
      render: (item) => {
        return (
          <>
            <Link to={`/?historyQueryData=${item.queryData}`}>
              <Button icon={<ArrowRightOutlined />} />
            </Link>


            <Popconfirm
              title="Удалить сохраненный запрос"
              description="Вы уверены, что хотите удалить запрос?"
              onConfirm={() => requestSavedDelete(item.id)}
              okText="Да"
              cancelText="Нет"
              placement="topLeft"
            >
              <Button danger icon={<DeleteOutlined />} style={{
                marginLeft: 10,
              }} />
            </Popconfirm>
          </>
        )
      }
    },
  ]

  const requestSavedDelete = (id) => {
    if (parseInt(id) === 0) {
      openErrorNotification('topRight', 'Некорректный параметр ID!');
      return false;
    }

    axiosInstance({
      url: '/deleteQuery',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('react-token')
      },
      params: {
        id,
      }
    })
      .then(response => {
        // TODO добавить вывод ошибки с сервера
        if (response.status === 200 && response?.data?.status) {
          openSuccessNotification('topRight', 'Запрос успешно удален!');
          requestSavedQueries();
        } else if (response?.data?.error) {
          openErrorNotification('topRight', response.data.error);
        } else {
          openErrorNotification('topRight', 'Возникла ошибка при удалении запроса!');
        }

      })
      .catch(function (error) {
        console.log(error)
        openErrorNotification('topRight', 'Возникла ошибка при удалении запроса!');
        return Promise.reject(error);
      })
  }

  const requestHistoryQueries = () => {
    setLoadingHistory(true);
    axiosInstance({
      url: '/getHistoryRequests',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('react-token')
      },
      params: {
        page: hPage,
        pageSize: hPageSize
      }
    })
      .then(response => {
        if (response.status === 200 && response?.data?.requests) {
          setHistoryDataSource(response.data.requests);
          setHTotal(response.data.total);
        } else if (response?.data?.error) {
          openErrorNotification('topRight', response.data.error);
        } else {
          openErrorNotification('topRight', 'Возникла ошибка при получении данных!');
        }
      })
      .catch(function (error) {
        openErrorNotification('topRight', 'Возникла ошибка при получении данных!');
        return Promise.reject(error);
      }).finally(() => {
        setTimeout(() => {
          setLoadingHistory(false);
        }, 200);
      });
  }

  const requestSavedQueries = () => {
    setLoadingSaved(true);
    axiosInstance({
      url: '/getSavedRequests',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('react-token')
      },
      params: {
        page: sPage,
        pageSize: sPageSize
      }
    })
      .then(response => {
        if (response.status === 200 && response?.data?.requests) {
          setSavedDataSource(response.data.requests);
          setSTotal(response.data.total);
        } else if (response?.data?.error) {
          openErrorNotification('topRight', response.data.error);
        } else {
          openErrorNotification('topRight', 'Возникла ошибка при получении данных!');
        }
      })
      .catch(function (error) {
        openErrorNotification('topRight', 'Возникла ошибка при получении данных!');
        return Promise.reject(error);
      }).finally(() => {
        setTimeout(() => {
          setLoadingSaved(false);
        }, 200);
      });
  }

  return (
    <>
      <Row type="flex" justify="space-around" align="top" gutter={24}>
        <Col span={12}>
          <Card title="История запросов" bordered={false} style={{
            marginTop: 10,
            marginBottom: 0
          }}>
            <Table
              bordered
              loading={loadingHistory}
              columns={historyColumns}
              dataSource={historyDataSource}
              locale={{
                triggerDesc: 'Отсортировать по убыванию',
                triggerAsc: 'Отсортировать по возрастанию',
                cancelSort: 'Отменить сортировку',
                emptyText: 'Данные отсутствуют'
              }}
              pagination={{
                current: hPage,
                pageSize: hPageSize,
                total: hTotal,
                position: ['bottomCenter'],
                locale: { items_per_page: '/ стр.' },
                onChange: (page) => {
                  setHPage(page)
                  setTimeout(() => {
                    requestHistoryQueries()
                  }, 100);
                },
                onShowSizeChange: (current, size) => {
                  setHPageSize(size)
                  setTimeout(() => {
                    requestHistoryQueries()
                  }, 100);
                }
              }}
            >

            </Table>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Сохраненные запросы" bordered={false} style={{
            marginTop: 10,
            marginBottom: 0
          }}>
            <Table
              bordered
              loading={loadingSaved}
              columns={savedColumns}
              dataSource={savedDataSource}
              locale={{
                triggerDesc: 'Отсортировать по убыванию',
                triggerAsc: 'Отсортировать по возрастанию',
                cancelSort: 'Отменить сортировку',
                emptyText: 'Данные отсутствуют'
              }}
              pagination={{
                current: sPage,
                pageSize: sPageSize,
                total: sTotal,
                position: ['bottomCenter'],
                locale: { items_per_page: '/ стр.' },
                onChange: async (page) => {
                  setSPage(page)
                  requestSavedQueries()
                },
                onShowSizeChange: async (current, size) => {
                  setSPageSize(size)
                  requestSavedQueries()
                }
              }}
            >

            </Table>
          </Card>
        </Col>
      </Row >


      <Context.Provider>
        {contextHolder}
      </Context.Provider>
    </>
  )
};

export default HistoryPage;
