import React from 'react';
import { Tabs } from 'antd';
import Axes from './Axes';
import Options from './Options';
export default function Pivot({ pivotConfig, pivotFields, onMove, onUpdate }) {
  return (
    <Tabs
      style={{
        width: 540,
      }}
    >
      <Tabs.TabPane tab="Трансформация" key="1">
        <Axes pivotConfig={pivotConfig} pivotFields={pivotFields} onMove={onMove} />
      </Tabs.TabPane>

      {/* <Tabs.TabPane tab="Параметры" key="2">
        <Options pivotConfig={pivotConfig} onUpdate={onUpdate} />
      </Tabs.TabPane> */}
    </Tabs>
  );
}
