import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DragOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
export default function Item({ id, index, pivotFields }) {
  const [label, setLabel] = useState('')
  useEffect(() => {
    if (id === 'measures') {
      setLabel('Разрез показателя')
    } else {
      for (const d of pivotFields) {
        if (d.name == id) setLabel(d.shortTitle )
      }
      if (label === '') setLabel(id)
    }
  })

  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          style={{ ...draggableProps.style }}
        >
          <Typography.Text
            ellipsis
            style={{
              maxWidth: '100%',
            }}
          >
            <DragOutlined
              style={{
                marginRight: 8,
              }}
            />
            {label}
          </Typography.Text>
        </div>
      )}
    </Draggable>
  );
}
