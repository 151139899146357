import { keycloak } from './keycloakInstance'

const TOKEN_MIN_VALIDITY_SECONDS = 50

export async function updateToken () {
   await keycloak.updateToken(TOKEN_MIN_VALIDITY_SECONDS)
   localStorage.setItem('react-token', keycloak.token)
   return keycloak.token
}

export async function logout () {
    return keycloak.logout({ redirectUri : "https://olap.miccedu.ru/" })
}