import React from 'react';
import { Card, Menu, Button, Dropdown, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Icon } from '@ant-design/compatible';
import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import { DELETE_DASHBOARD_ITEM } from '../graphql/mutations';
import 'table2excel';

const TableExcel = () => {
  if (document.querySelectorAll(".tableRender").length > 0) {
    redirectPost(`https://html2xlsx.gateway.miccedu.ru/export/`, {
      file: document.querySelectorAll(".tableRender")[0].innerHTML
    })
  } else {
    alert('таблица не найдена')
  }
}

function redirectPost(url, data) {
  var form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  for (var name in data) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  }
  form.submit();
}

const DashboardItemDropdown = ({ itemId }) => {
  const [removeDashboardItem] = useMutation(DELETE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS,
      },
    ],
  });
  const dashboardItemDropdownMenu = (
    <Menu items={[
      {
        label: (
          <div onClick={TableExcel}>XLSX</div>
        ),
        key: 'm1'
      },
      {
        label: (
          <Link to={`/?itemId=${itemId}`}>Редактировать</Link>
        ),
        key: 'm2'
      },
      {
        label: (
          <div onClick={() =>
            Modal.confirm({
              title: 'Вы подтверждаете данное действие?',
              okText: 'Да',
              okType: 'danger',
              cancelText: 'Нет',

              onOk() {
                removeDashboardItem({
                  variables: {
                    id: itemId,
                  },
                });
              },
            })
          }>Удалить</div>

        ),
        key: 'm3'
      },
    ]}>
    </Menu>
  );
  return (
    <Dropdown
      overlay={dashboardItemDropdownMenu}
      placement="bottomLeft"
      trigger={['click']}
    >
      <Button shape="circle" icon={<Icon type="menu" />} />
    </Dropdown>
  );
};

const DashboardItem = ({ itemId, children, title }) => (
  <Card
    title={title}
    style={{
      position: 'relative',
      height: '100%',
      width: '100%',
    }}
    bodyStyle={{ maxHeight: 'calc(100% - 65px)', overflow: 'hidden' }}
    extra={<DashboardItemDropdown itemId={itemId} />}
  >
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      {children}
    </div>
  </Card>
);

export default DashboardItem;
