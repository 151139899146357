import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import ExplorePage from './pages/ExplorePage';
import DashboardPage from './pages/DashboardPage';
import HistoryPage from './pages/HistoryPage';
import App from './App';

import { keycloak, initOptions } from './plugins/keycloakInstance'

keycloak.init({ onLoad: initOptions.onLoad }).then(async (auth) => {

  if (!auth) {
    window.location.reload();
  } else {
    console.info("Authenticated");
  }

  localStorage.setItem("react-token", keycloak.token);
  localStorage.setItem("react-refresh-token", keycloak.refreshToken);

  // ? Небольшая задержка для прогрузки контента
  await new Promise((resolve) => {
    setTimeout(resolve, 50)
  })

  ReactDOM.render(
    <Router>
      <App>
        <Route key="index" exact path="/" component={ExplorePage} />
        <Route key="explore" path="/explore" component={DashboardPage} />
        <Route key="history" path="/history" component={HistoryPage} />
      </App>
    </Router>, // eslint-disable-next-line no-undef
    document.getElementById('root')
  );

  setTimeout(() => {
    keycloak.updateToken(10).then((refreshed) => {
      if (refreshed) {
        console.debug('Token refreshed' + refreshed);
      } else {
        console.warn('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      console.error('Failed to refresh token');
    });
  }, 60000)

}).catch(() => {
  console.error("Authenticated Failed");
});
