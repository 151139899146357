import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Select, Input } from 'antd';
const FilterInputs = {
    string: ({ values, onChange }) => (
        // <Select
        //   key="input"
        //   style={{
        //     width: 300,
        //   }}
        //   mode="tags"
        //   onChange={onChange}
        //   value={values}
        // />
        <Input
            key="filter_input_string"
            style={{
                width: 300
            }}
            type="text"
            onChange={(e) => onChange([e.target.value])}
            value={(values && values[0]) || ''}
        />
    ),
    number: ({ values, onChange }) => (
        <Input
            key="filter_input_number"
            style={{
                width: 300
            }}
            type="number"
            onChange={(e) => onChange([e.target.value])}
            value={(values && values[0]) || ''}
        />
    ),
    search: ({ values, options, onChange, type }) => {
        const preparedOptions = [];
       
        const _options = []
        for (const opt of options) {
            if (opt && opt !== null && typeof opt !== 'undefined') _options.push({
                label: String(opt),
                value: typeController(opt, type)
            })
        }

        return (
            <Select
                key="filter_select"
                showSearch
                placeholder="Введите значение"
                style={{
                    width: 620,
                }}
                options={_options}
                mode="tags"
                onChange={onChange}
                value={values}
            />
        );
    }
};

FilterInputs.string.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func.isRequired
};
FilterInputs.string.defaultProps = {
    values: []
};
FilterInputs.number.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func.isRequired
};
FilterInputs.number.defaultProps = {
    values: []
};

const typeController = (value, type) => {
    let _value = null;

    switch (type) {
        case 'string':
            _value = value.toString().trim();
            break;
        case 'number':
            _value = parseInt(value);
            break;
        default:
            _value = value.toString().trim();
            break;
    }

    return _value;
};

const FilterInput = ({ member, updateMethods, searchData }) => {
    // const Filter = FilterInputs[member.dimension.type] || FilterInputs.string;
    const valueType = member?.dimension?.type ? member?.dimension?.type : 'string';
    const Filter = searchData[member.member] && searchData[member.member].length > 0 ? FilterInputs.search : FilterInputs[valueType] || FilterInputs.string;
    
    return (
        <Filter
            key="filter"
            values={member.values}
            options={searchData[member.member]}
            onChange={(values) => {
                updateMethods.update(member, { ...member, values });
            }}
            type={valueType}
        />
    );
};

FilterInput.propTypes = {
    member: PropTypes.object.isRequired,
    updateMethods: PropTypes.object.isRequired,
    searchData: PropTypes.object.isRequired,
};
export default FilterInput;
