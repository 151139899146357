import { Select, Space } from 'antd';
import React, { useState, useEffect } from 'react';

const TagInput = ({ initialValue, setValue, members, availableMembers, addMemberName, updateMethods, updateMethodsWrapper, onClick, onRemoveClick }) => {
    // const [value, setValue] = useState([...initialValue]);

    const options = [];

    availableMembers.map((m, index) => {
        options.push({
            label: `${m.shortTitle}`,
            value: m.name,
        });
    })

    const handleOnClick = (i) => {
        // onClick(availableMembers[i])
        // setValue([...value, i])
        const values = Object.values(availableMembers);
        for (let index in values) {
            if (values[index]?.name === i) {
                onClick(values[index])
                setValue([...initialValue, values[index].name])
            }
        }
    }

    const handleOnRemoveClick = (i) => {
        const values = Object.values(members);
        for (let index in values) {
            if (values[index]?.name === i) {
                updateMethods.remove(values[index])
                break
            }
        }

        let _value = []
        initialValue.map((v, j) => {
            if (v !== i) _value.push(v)
        })
        setValue(_value)
    }


    useEffect(() => {
        if (initialValue?.length === 0 && initialValue && typeof initialValue === 'object' && initialValue?.length > 0) {
            setValue(initialValue);
        }
    }, [initialValue])

    const selectProps = {
        mode: 'multiple',
        style: {
            width: '100%',
        },
        size: 'large',
        options,
        value: initialValue,
        onSelect: (newValue) => handleOnClick(newValue),
        onDeselect: (newValue) => handleOnRemoveClick(newValue),
        placeholder: addMemberName,
        maxTagCount: 'responsive',
    };

    return (
        <>
            <Select {...selectProps} />
        </>
    )
}

export default TagInput;