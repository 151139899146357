import axios from 'axios';
import { keycloak } from './keycloakInstance'

const axiosInstance = axios.create({
   // baseURL: "http://localhost:7000/",
   baseURL: "https://olap-backend.docker.miccedu.ru/",
   headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + String(keycloak.token)
   },
   timeout: 600000,
   withCredentials: false
});

export default axiosInstance