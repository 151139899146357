import React, { useState, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import { QueryBuilder } from '@cubejs-client/react';
import ChartRenderer from '../ChartRenderer';
import ControlBar from './ControlBar';

const ExploreQueryBuilder = ({ vizState, cubejsApi, chartExtra, onVizStateChanged, setRowsLimitAlert }) => {
  const [isLoadDynamic, setDynamicLoad] = useState(true);
  const [rateLimit, setRateLimit] = useState('1000');

  return (
    <QueryBuilder
      initialVizState={vizState}
      vizState={vizState}
      cubejsApi={cubejsApi}
      wrapWithQueryRenderer={false}
      onVizStateChanged={onVizStateChanged}
      render={({
        validatedQuery,
        isQueryPresent,
        chartType,
        updateChartType,
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        orderMembers,
        updateOrder,
        pivotConfig,
        updatePivotConfig,
        updateQuery
      }) => {
        const getQueryWithFilters = () => {
          let q = validatedQuery ? validatedQuery : vizState?.query;

          if (q) {
            /// делаем это дерьмо, чтоб при удалении фильтра(-ов) все ровно работало
            q.filters = undefined;
          }

          if (q && isQueryPresent && orderMembers && orderMembers.length > 0) {
            let o = {}

            for (const orderItem of orderMembers) {
              if (orderItem.order !== 'none') o[orderItem.id] = orderItem.order
            }

            q.order = o
          }

          if (q && filters && filters.length > 0) {
            /// делаем это дерьмо чтоб фильтры работали как веник в бане
            let f = []

            for (const filterItem of filters) {
              if (filterItem && filterItem.member &&
                filterItem.operator &&
                filterItem.values &&
                Array.isArray(filterItem.values) &&
                filterItem.values.length > 0) {

                f.push({
                  member: filterItem.member,
                  operator: filterItem.operator,
                  values: filterItem.values
                });
              }
            }

            if (f.length > 0) q.filters = f;
          }

          return q;
        };

        return (
          <Fragment>
            <ControlBar
              vizState={vizState}
              validatedQuery={validatedQuery}
              isQueryPresent={isQueryPresent}
              chartType={chartType}
              updateChartType={updateChartType}
              measures={measures}
              availableMeasures={availableMeasures}
              updateMeasures={updateMeasures}
              dimensions={dimensions}
              availableDimensions={availableDimensions}
              updateDimensions={updateDimensions}
              segments={segments}
              availableSegments={availableSegments}
              updateSegments={updateSegments}
              filters={filters}
              updateFilters={updateFilters}
              timeDimensions={timeDimensions}
              availableTimeDimensions={availableTimeDimensions}
              updateTimeDimensions={updateTimeDimensions}
              orderMembers={orderMembers}
              updateOrder={updateOrder}
              pivotConfig={pivotConfig}
              updatePivotConfig={updatePivotConfig}
              getQueryWithFilters={getQueryWithFilters}
              rateLimit={rateLimit}
              setRateLimit={setRateLimit}
              isLoadDynamic={isLoadDynamic}
              setDynamicLoad={setDynamicLoad}
              updateQuery={updateQuery}
            ></ControlBar>

            <Row type="flex" justify="space-around" align="top" gutter={24}>
              <Col span={24}>
                {isQueryPresent ? (
                  <Card
                    style={{
                      minHeight: 420
                    }}
                    extra={chartExtra}
                  >
                    {isLoadDynamic &&
                      <ChartRenderer
                        vizState={{
                          query: getQueryWithFilters(),
                          chartType,
                          pivotConfig,
                          rateLimit
                        }}
                        setRowsLimitAlert={setRowsLimitAlert}
                      />
                    }
                    {!isLoadDynamic &&
                      <h2 style={{ textAlign: 'center' }}>Для отображения данных включите динамическую загрузку таблицы</h2>
                    }
                  </Card>
                ) : (
                  <div>
                    <h2 style={{ textAlign: 'center' }}>Укажите параметры запроса, чтобы начать работу</h2>
                    {/* {tmpAvailableMeasures && tmpAvailableMeasures.length > 0 && <h2 style={{ textAlign: 'center' }}>Выберите показатель или разрез показателя</h2>}
                    {(!tmpAvailableMeasures || tmpAvailableMeasures.length === 0) && <h2 style={{ textAlign: 'center' }}> Выберите куб, чтобы начать работу</h2>} */}
                  </div>
                )}
              </Col>
            </Row>
          </Fragment>
        );
      }}
    />
  );
};

ExploreQueryBuilder.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
  chartExtra: PropTypes.array,
  setRowsLimitAlert: PropTypes.func,
};
ExploreQueryBuilder.defaultProps = {
  vizState: {},
  cubejsApi: null,
  chartExtra: null,
  setRowsLimitAlert: null
};

export default ExploreQueryBuilder;