import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { Droppable } from 'react-beautiful-dnd';
import Item from './Item';
export default function DroppableArea({ pivotConfig, pivotFields, axis }) {

  return (
    <>
      <Typography.Text
        strong
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {axis}
      </Typography.Text>
      <Droppable droppableId={axis}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height: '100%',
            }}
          >
            {pivotConfig[axis].map((id, index) => (
              <Item key={id} id={id} index={index} pivotFields={pivotFields} />
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
