import { Select } from 'antd';
import React, { useState, useEffect } from 'react';

const LimitSelect = ({ value, setRateLimit }) => {
  const options = [
    {
      value: '100',
      label: '100',
    },
    {
      value: '1000',
      label: '1000',
    },
    {
      value: '10000',
      label: '10000',
    },
    {
      value: 'all',
      label: 'Все',
    },
  ];



  const handleChange = (_value) => {
    setRateLimit(_value)
  }


  return (
    <Select
      // defaultValue={'1000'}
      value={value}
      onChange={handleChange}
      style={{ width: 120 }}
      options={options}
    />
  )
}

export default LimitSelect;