import React, { useState } from 'react';
import { Alert, Button, Spin, Space, Modal, Input, Form, notification } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import ExploreQueryBuilder from '../components/QueryBuilder/ExploreQueryBuilder';
import { GET_DASHBOARD_ITEM } from '../graphql/queries';
import TitleModal from '../components/TitleModal.js';
import axiosInstance from '../plugins/axiosInstance';
import 'table2excel';

const Context = React.createContext({
  name: 'Default',
});

const TableExcel = () => {
  if (document.querySelectorAll(".tableRender").length > 0) {
    redirectPost(`https://html2xlsx.gateway.miccedu.ru/export/`, {
      file: document.querySelectorAll(".tableRender")[0].innerHTML
    })
  } else {
    alert('таблица не найдена')
  }
}

function redirectPost(url, data) {
  var form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  for (var name in data) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  }
  form.submit();
}

const RowsLimitAlertElement = ({ rowsLimitAlert }) => {
  return (
    <>
      {rowsLimitAlert &&
        <Space direction='vertical'>
          <Alert style={{ width: 530, marginRight: "10px" }} message="Установлено ограничение на количество отображаемых в таблице строк" type="warning" showIcon />
        </Space>
      }
    </>
  )
}

const ExplorePage = withRouter(({ history, location }) => {
  const [rowsLimitAlert, setRowsLimitAlert] = useState(false)
  const [titleModalVisible, setTitleModalVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const [vizState, setVizState] = useState(null);
  const [isSaveQueryModalOpen, setSaveQueryModalOpen] = useState(false);
  const [saveQueryLoading, setSaveQueryLoading] = useState(false);
  const [addingToDashboard, setAddingToDashboard] = useState(false);

  let finalVizState = {};
  const params = new URLSearchParams(location.search);
  const itemId = params.get('itemId');
  const historyQueryData = params.get('historyQueryData');

  const [api, contextHolder] = notification.useNotification();

  const { loading, error, data } = useQuery(GET_DASHBOARD_ITEM, {
    variables: {
      id: itemId,
    },
    skip: !itemId,
  });

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert type="error" message={error.toString()} />;
  }

  const openErrorNotification = (placement, text) => {
    api.error({
      message: `Ошибка`,
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      placement,
    });
  };

  const openSuccessNotification = (placement, text) => {
    api.success({
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      placement,
    });
  };


  if (itemId && !loading && data && JSON.parse(data.dashboardItem.vizState)) {
    finalVizState = { ...JSON.parse(data.dashboardItem.vizState) }
  } else if (!itemId && !loading && historyQueryData) {
    try {
      // finalVizState = {
      //   ...JSON.parse(historyQueryData)
      // }

      finalVizState = {
        chartType: "table",
        pivotConfig: {
          "x": [],
          "y": [],
          "fillMissingDates": true,
          "joinDateRange": false
        },
        query: JSON.parse(historyQueryData)
      }
    } catch (error) {
      openErrorNotification('topRight', 'Возникла ошибка при обработке параметров запроса!');
    }
  } else if (vizState) {
    finalVizState = { ...vizState }
  } else {
    // finalVizState = {}
    finalVizState = {
      "chartType": "table",
      "pivotConfig": {
        "x": [],
        "y": [],
        "fillMissingDates": true,
        "joinDateRange": false
      },
      "query": {}
    }
  }

  // TODO check rerender starting from here
  // console.log("finalVizState")
  // console.log(finalVizState)
  // console.log("vizState")
  // console.log(vizState)

  const finalTitle =
    title != null
      ? title
      : (itemId && !loading && data && data.dashboardItem.name) || 'Название панели';



  const closeSaveQueryModal = () => {
    setSaveQueryModalOpen(false);
  }

  const openSaveQueryModal = () => {
    setSaveQueryModalOpen(true);
  }

  const onSaveQuery = ({ savedQueryName }) => {
    setSaveQueryLoading(true);
    saveQuery(savedQueryName)
    setSaveQueryModalOpen(false);
  }

  const onSaveQueryFail = (errorInfo) => {
    // TODO fix it
  };

  const saveQuery = (queryName) => {
    const queryData = finalVizState?.query || {};
    if (queryData && Object.keys(queryData)?.length === 0) {
      openErrorNotification('topRight', 'Возникла ошибка при подготовке параметров запроса!');
      return false;
    }

    axiosInstance({
      url: '/saveQuery',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('react-token')
      },
      data: {
        queryName,
        queryData
      }
    })
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.status) {
            openSuccessNotification('topRight', 'Запрос успешно сохранен!');
          } else if (response?.data?.error) {
            openErrorNotification('topRight', response.data.error);
          } else {
            openErrorNotification('topRight', 'Возникла ошибка при сохранении запроса!');
          }
        } else {
          openErrorNotification('topRight', 'Возникла ошибка при сохранении запроса!');
        }
      })
      .catch(function (error) {
        console.log(error)
        openErrorNotification('topRight', 'Возникла ошибка при сохранении запроса!');
        return Promise.reject(error);
      }).finally(() => {
        setTimeout(() => {
          setSaveQueryLoading(false);
        }, 200);
      });
  }

  return (
    <div>
      <TitleModal
        history={history}
        itemId={itemId}
        titleModalVisible={titleModalVisible}
        setTitleModalVisible={setTitleModalVisible}
        setAddingToDashboard={setAddingToDashboard}
        finalVizState={finalVizState}
        setTitle={setTitle}
        finalTitle={finalTitle}
      />

      {/* query {JSON.stringify(finalVizState?.query)}
      <br></br>
      pivotConfig {JSON.stringify(finalVizState?.pivotConfig)} */}

      <ExploreQueryBuilder
        vizState={finalVizState}
        chartExtra={[
          <RowsLimitAlertElement key="alert3" rowsLimitAlert={rowsLimitAlert} />,
          // <Button key="button1" style={{ marginRight: "10px" }} onClick={TableExcel}>
          //   XLSX
          // </Button>,
          <Button
            key="button2"
            style={{ marginRight: "10px" }}
            type="dashed"
            loading={addingToDashboard}
            onClick={() => setTitleModalVisible(true)}
          >
            {itemId ? 'Обновить' : 'Сохранить в панелях'}
          </Button>,
          <Button
            key="button3"
            type="primary"
            onClick={() => openSaveQueryModal()}
          >
            Сохранить в БД
          </Button>
        ]}
        onVizStateChanged={setVizState}
        setRowsLimitAlert={setRowsLimitAlert}
      />
      <Modal title="Сохранить запрос" open={isSaveQueryModalOpen}
        footer={[
          <Button onClick={() => closeSaveQueryModal()} key="modalBtn1">
            Отменить
          </Button>,
          <Button form="basic" type="primary" htmlType="submit" loading={saveQueryLoading} key="modalBtn2">
            Сохранить
          </Button>
        ]}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 26 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          layout={'vertical'}
          onFinish={onSaveQuery}
          onFinishFailed={onSaveQueryFail}
        >
          <Form.Item
            label="Название запроса"
            name="savedQueryName"
            rules={[{ required: true, message: 'Пожалуйста, введите название запроса!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Context.Provider>
        {contextHolder}
      </Context.Provider>
    </div>
  );
});
export default ExplorePage;
