import React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from '@ant-design/compatible';
import TagInput from './TagInput'

const MemberGroupWithSelect = ({
  members,
  availableMembers,
  value,
  setValue,
  addMemberName,
  updateMethods,
  memberType
}) => {

  return (

    <div style={{
      display: 'inline-block',
      marginBottom: -10,
      width: "50%"
    }}>
      <TagInput
        initialValue={value}
        setValue={setValue}
        members={members}
        availableMembers={availableMembers}
        addMemberName={addMemberName}
        updateMethods={updateMethods}
        onClick={(m) => updateMethods.add(m)}
        // onClick={(m) => updateMethodsWrapper(memberType, 'a', [m])}
        onRemoveClick={(m) => updateMethods.remove(m)}
      // onRemoveClick={(m) => updateMethodsWrapper(memberType, 'r', [])}
      />
    </div>
  )
};

MemberGroupWithSelect.propTypes = {
  members: PropTypes.array.isRequired,
  availableMembers: PropTypes.array.isRequired,
  addMemberName: PropTypes.string.isRequired,
  updateMethods: PropTypes.object.isRequired,
  memberType: PropTypes.string.isRequired,
};

export default MemberGroupWithSelect;
