import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Card, Popover, Button, Select, Checkbox, Form, notification } from 'antd';
import { SortAscendingOutlined, BorderInnerOutlined } from '@ant-design/icons';
import MemberGroup from './MemberGroup';
import MemberGroupWithSelect from './MemberGroupWithSelect';
import FilterGroup from './FilterGroup';
import TimeGroup from './TimeGroup';
import SelectChartType from './SelectChartType';
import OrderGroup from './Order/OrderGroup';
import Pivot from './Pivot/Pivot';
import LimitSelect from './LimitSelect';
import axiosInstance from '../../plugins/axiosInstance';

// import QueryStore from '../../stores/query-store';
// import { observer } from 'mobx-react-lite';

const { Option } = Select;

const Context = React.createContext({
  name: 'Default',
});

function divideCubes(availableMeasures, availableDimensions, availableSegments, availableTimeDimensions) {
  let availableCubes = [];
  let availableCubesNames = [];
  let divAvailableMeasures = {};

  availableMeasures.forEach((am) => {
    availableCubes.push(am.title.replace(am.shortTitle, ''));
    let tmpCubeName = am.name.split('.')[0];
    availableCubesNames.push(tmpCubeName);
    divAvailableMeasures[tmpCubeName] ? divAvailableMeasures[tmpCubeName].push(am) : (divAvailableMeasures[tmpCubeName] = [am]);
  });

  let divAvailableDimensions = {};
  availableDimensions.forEach((ad) => {
    let tmpCubeName = ad.name.split('.')[0];
    divAvailableDimensions[tmpCubeName] ? divAvailableDimensions[tmpCubeName].push(ad) : (divAvailableDimensions[tmpCubeName] = [ad]);
  });

  let divAvailableSegments = {};
  availableSegments.forEach((as) => {
    let tmpCubeName = as.name.split('.')[0];
    divAvailableSegments[tmpCubeName] ? divAvailableSegments[tmpCubeName].push(as) : (divAvailableSegments[tmpCubeName] = [as]);
  });

  let divAvailableTimeDimensions = {};
  availableTimeDimensions.forEach((atd) => {
    let tmpCubeName = atd.name.split('.')[0];
    divAvailableTimeDimensions[tmpCubeName] ? divAvailableTimeDimensions[tmpCubeName].push(atd) : (divAvailableTimeDimensions[tmpCubeName] = [atd]);
  });

  availableCubes = Array.from(new Set(availableCubes));
  availableCubesNames = Array.from(new Set(availableCubesNames));
  for (var i in availableCubes) {
    availableCubes[i] = { id: i, name: availableCubes[i], value: availableCubesNames[i] };
  }
  return {
    defaultCube: availableCubesNames[0],
    availableCubes: availableCubes,
    divAvailableMeasures: divAvailableMeasures,
    divAvailableDimensions: divAvailableDimensions,
    divAvailableSegments: divAvailableSegments,
    divAvailableTimeDimensions: divAvailableTimeDimensions,
    divFilters: []
  };
}

function translateFilters(filters) {
  filters.forEach((f) => {
    f.operators.forEach((fo) => {
      switch (fo.name) {
        case 'contains':
          fo.title = 'содержит';
          break;
        case 'notContains':
          fo.title = 'не содержит';
          break;
        case 'equals':
          fo.title = '=';
          break;
        case 'notEquals':
          fo.title = '≠';
          break;
        case 'set':
          fo.title = '≠ NULL';
          break;
        case 'notSet':
          fo.title = '== NULL';
          break;
        case 'startsWith':
          fo.title = 'начинается с';
          break;
        case 'endsWith':
          fo.title = 'заканчивается на';
          break;
        case 'gt':
          fo.title = '>';
          break;
        case 'gte':
          fo.title = '>=';
          break;
        case 'lt':
          fo.title = '<';
          break;
        case 'lte':
          fo.title = '<=';
          break;
        case 'inDateRange':
          fo.title = 'в диапазоне дат';
          break;
        case 'notInDateRange':
          fo.title = 'не в диапазоне дат';
          break;
        case 'beforeDate':
          fo.title = 'до даты';
          break;
        case 'afterDate':
          fo.title = 'после даты';
          break;
      }
    });
  });
}

function prepareOrderMember(orderMembers) {
  let orders = {
    none: 'Не выбрано',
    asc: 'По возрастанию',
    desc: 'По убыванию'
  };

  orderMembers = orderMembers.map((orderMember) => {
    if (orderMember.order) orderMember.orderLabel = orders[orderMember.order];
    return orderMember;
  });
}

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};


const ControlBar = ({
  vizState,
  validatedQuery,
  isQueryPresent,
  chartType,
  updateChartType,
  measures,
  availableMeasures,
  updateMeasures,
  dimensions,
  availableDimensions,
  updateDimensions,
  segments,
  availableSegments,
  updateSegments,
  filters,
  updateFilters,
  timeDimensions,
  availableTimeDimensions,
  updateTimeDimensions,
  orderMembers,
  updateOrder,
  pivotConfig,
  updatePivotConfig,
  getQueryWithFilters,
  rateLimit,
  setRateLimit,
  isLoadDynamic,
  setDynamicLoad,
  updateQuery
}) => {
  const [tmpAvailableMeasures, setTmpAvailableMeasures] = useState([]);
  const [tmpAvailableDimensions, setTmpAvailableDimensions] = useState([]);
  const [tmpAvailableSegments, setTmpAvailableSegments] = useState([]);
  const [tmpAvailableTimeDimensions, setTmpAvailableTimeDimensions] = useState([]);
  const [requestExcelBtnLoader, setRequestExcelBtnLoader] = useState(false);
  const [cubeSelectValue, setCubeSelectValue] = useState('');
  const [measuresSelectValue, setMeasuresSelectValue] = useState([]);
  const [dimensionsSelectValue, setDimensionsSelectValue] = useState([]);
  // const [filtersSelectValue, setFiltersSelectValue] = useState([]);

  // const [segmentsSelectValue, setSegmentsSelectValue] = useState([]);
  // const [timeDimensionsSelectValue, setTimeDimensionsSelectValue] = useState([]);
  // const { storedQuery, setStoredQuery, test, increment, storeAvailableMeasures } = QueryStore;

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.error({
      message: `Ошибка`,
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      placement,
    });
  };

  const { defaultCube, availableCubes, divAvailableMeasures, divAvailableDimensions, divAvailableSegments, divAvailableTimeDimensions } = divideCubes(
    availableMeasures,
    availableDimensions,
    availableSegments,
    availableTimeDimensions
  );

  const formItemLayout = {
    labelCol: {
      // span: 4,
    },
    wrapperCol: {
      // span: 10,
    }
  };

  useEffect(() => {
    if (vizState?.query?.measures?.length > 0 && vizState?.query?.dimensions?.length > 0) {
      const distinctDataCube = [...new Set([...vizState.query.measures, ...vizState.query.dimensions].map((item) => {
        return item.split(".")[0]
      })
      )];

      if (distinctDataCube && distinctDataCube.length > 0) {
        setCubeSelectValue(distinctDataCube[0])
        setControlsOptions(distinctDataCube[0]);
        setControlsValues(distinctDataCube[0]);
        // setMeasuresSelectValue([...vizState.query.measures])
        // setCubeState(distinctDataCube)
      }
    }

    if (typeof vizState?.query === 'object' && Object.keys(vizState.query)?.length === 0) {
      setCubeSelectValue('Выберите куб')
    }
  }, [vizState]);

  // setTest(divAvailableMeasures);
  const setControlsOptions = debounce((value) => {
    if (divAvailableMeasures[value] && tmpAvailableMeasures?.length === 0) setTmpAvailableMeasures(divAvailableMeasures[value]);
    if (divAvailableDimensions[value] && tmpAvailableDimensions?.length === 0) setTmpAvailableDimensions(divAvailableDimensions[value]);
    // if (divAvailableSegments[value] && tmpAvailableSegments?.length === 0) setTmpAvailableSegments(divAvailableSegments[value]);
    // if (divAvailableTimeDimensions[value] && tmpAvailableTimeDimensions?.length === 0) setTmpAvailableTimeDimensions(divAvailableTimeDimensions[value]);
  }, 100);

  const setControlsValues = debounce((value) => {
    if (divAvailableMeasures[value] && tmpAvailableMeasures?.length === 0) setMeasuresSelectValue(vizState?.query?.measures);
    if (divAvailableDimensions[value] && tmpAvailableDimensions?.length === 0) setDimensionsSelectValue(vizState?.query?.dimensions);
    // if (divAvailableSegments[value] && tmpAvailableSegments?.length === 0) setSegmentsSelectValue(vizState?.query?.measures);
    // if (divAvailableTimeDimensions[value] && tmpAvailableTimeDimensions?.length === 0) setTimeDimensionsSelectValue(vizState?.query?.measures);
  }, 100);

  const handleCubeChange = debounce((value) => {
    setCubeSelectValue(value)

    setTmpAvailableMeasures(divAvailableMeasures[value]);
    setTmpAvailableDimensions(divAvailableDimensions[value]);
    setTmpAvailableSegments(divAvailableSegments[value]);
    setTmpAvailableTimeDimensions(divAvailableTimeDimensions[value]);
  }, 100);

  translateFilters(filters);
  prepareOrderMember(orderMembers);

  const toggleDynamicLoad = (e) => {
    setDynamicLoad(!isLoadDynamic)
  };

  function getQueryLimit() {
    let rl = 1000;

    switch (rateLimit) {
      case '100':
        rl = 100;
        break;

      case '1000':
        rl = 1000;
        break;

      case '10000':
        rl = 10000;
        break;

      case 'all':
        rl = 50000;
        break;

      default:
        rl = 10000;
        break;
    }

    return rl;
  };

  async function requestXLSX() {
    let query = getQueryWithFilters()

    if (query && typeof query == 'object' && Object.keys(query).length > 0) {
      let excel_name = 'export';
      try {
        excel_name = document.querySelector(".cubeName").querySelector(".ant-select-selection-item").innerText.trim()

        var iso = new Date().toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2})/)
        let _hgroup = iso[2].split(":");
        _hgroup[0] = parseInt(_hgroup[0]) + 3;
        excel_name += `(${iso[1]} ${_hgroup[0]}-${_hgroup[1]})`

        excel_name = excel_name.replaceAll(" | ", "-")
      } catch (error) { }

      // Проствляем лимит для записей
      // const limit = getQueryLimit();
      query['limit'] = 50000;
      query['offset'] = 0;

      // PivotConfig check
      const pivotParams = { ...pivotConfig };

      if ((typeof pivotConfig?.x === 'object' && pivotConfig?.x?.length === 0) || typeof pivotConfig?.x === 'object' && pivotConfig?.y?.length === 0) {
        pivotParams.x = query?.dimensions;
        pivotParams.y = ["measures"];
      }

      // const searchParams = JSON.stringify(query);
      // let queryString = `/cubejs-api/v1/load?query=${searchParams.toString().length ? `${encodeURIComponent(searchParams)}` : ''}`;
      // let queryString = `/cubejs-api/v1/load?query=${searchParams.toString()}`;

      setRequestExcelBtnLoader(true);
      axiosInstance({
        url: '/manualExport',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + localStorage.getItem('react-token')
        },
        data: { query: query, pivotConfig: pivotParams },
        responseType: 'blob',
        options: { timeout: 600000 }
      })
        .then(response => {
          if (response.status === 200) {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${excel_name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setTimeout(() => {
              setRequestExcelBtnLoader(false);
            }, 200);
          } else {
            openNotification('topRight', 'Возникла ошибка при экспорте данных!');
            setTimeout(() => {
              setRequestExcelBtnLoader(false);
            }, 200);
          }
        })
        .catch(function (error) {
          openNotification('topRight', 'Возникла ошибка при экспорте данных!');
          setTimeout(() => {
            setRequestExcelBtnLoader(false);
          }, 200);
          return Promise.reject(error);
        });

    }
  }

  const isQueryValid = () => {
    try {
      if (!vizState?.chartType || !vizState?.query) return false;
      if (!vizState?.query?.measures || !vizState?.query?.dimensions) return false;
      if (vizState?.query?.measures && typeof vizState?.query?.measures === 'object' && vizState?.query?.measures?.length === 0) return false;
      if (vizState?.query?.dimensions && typeof vizState?.query?.dimensions === 'object' && vizState?.query?.dimensions?.length === 0) return false;
      return true;
    } catch (error) {
      console.log(error)
      return false;
    }
  }

  const resetQuery = () => {
    updateQuery({
      measures: [],
      dimensions: [],
      filters: [],
    });

    setMeasuresSelectValue([]);
    setDimensionsSelectValue([]);
  }

  return (
    <Row
      type="flex"
      justify="space-around"
      align="top"
      gutter={24}
      style={{
        marginBottom: 12
      }}
    >
      <Col span={24}>
        <Card>
          <Row
            type="flex"
            justify="start"
            gutter={24}
            style={{
              marginBottom: 12,
              alignItems: 'center'
            }}
          >
            <Col span={10} style={{ paddingRight: 0 }}>
              {defaultCube && (
                <Form {...formItemLayout} layout={'vertical'}>
                  <Form.Item label="Выберите куб:">
                    {/* {JSON.stringify(cubeSelectValue)} */}
                    <Select
                      // defaultValue={defaultCube}
                      defaultValue="Выберите куб"
                      style={{ width: '100%' }}
                      dropdownMatchSelectWidth="true"
                      value={cubeSelectValue}
                      onChange={handleCubeChange}
                      className='cubeName'
                    >
                      {availableCubes.map((c) => (
                        <Option key={c.id} value={c.value}>
                          {c.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              )}

              <Divider
                style={{
                  marginTop: 10,
                  marginBottom: 0
                }}
              />
            </Col>
            <Col span={10} style={{ marginLeft: 0, paddingLeft: 0 }}>
              {isQueryValid() && (
                <>
                  <Divider type="vertical" />
                  <Button style={{ marginLeft: 0 }} type="dashed" onClick={() => resetQuery()}>
                    Сбросить
                  </Button>
                  <Divider type="vertical" />
                  <Context.Provider>
                    {contextHolder}
                    <Button style={{ marginLeft: 0 }} type="primary" loading={requestExcelBtnLoader} onClick={() => requestXLSX()} disabled={false}>
                      Сформировать XLSX
                    </Button>
                  </Context.Provider>
                </>
              )}

              <Divider type="vertical" />
              <Checkbox checked={isLoadDynamic} onChange={toggleDynamicLoad}>Динамическая загрузка таблицы</Checkbox>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            align="top"
            gutter={24}
            style={{
              marginBottom: 0
            }}
          >
            <Col span={24}>
              {/* || (preloadedQuery && measuresSelectValue?.length > 0) */}
              {(tmpAvailableMeasures && tmpAvailableMeasures.length > 0) && (
                <>
                  {/* <MemberGroup
                  members={measures}
                  availableMembers={tmpAvailableMeasures}
                  addMemberName="Меры"
                  updateMethods={updateMeasures}
                /> */}

                  <Form {...formItemLayout} layout={'vertical'}>
                    <Form.Item label="Показатель:">
                      <MemberGroupWithSelect
                        members={measures}
                        availableMembers={tmpAvailableMeasures}
                        value={measuresSelectValue}
                        setValue={setMeasuresSelectValue}
                        addMemberName="Показатель"
                        updateMethods={updateMeasures}
                        // updateMethodsWrapper={updateMethodsWrapper}
                        memberType="measures"
                      />
                    </Form.Item>
                  </Form>
                </>
              )}
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            align="top"
            gutter={24}
            style={{
              marginBottom: 0
            }}
          >
            <Col span={24}>
              {tmpAvailableDimensions && tmpAvailableDimensions.length > 0 && (
                <>
                  {/* <MemberGroup
                  members={dimensions}
                  availableMembers={tmpAvailableDimensions}
                  addMemberName="Измерения"
                  updateMethods={updateDimensions}
                /> */}

                  <Form {...formItemLayout} layout={'vertical'}>
                    <Form.Item label="Разрез показателя:">
                      <MemberGroupWithSelect
                        members={dimensions}
                        availableMembers={tmpAvailableDimensions}
                        value={dimensionsSelectValue}
                        setValue={setDimensionsSelectValue}
                        addMemberName="Разрез показателя"
                        updateMethods={updateDimensions}
                        // updateMethodsWrapper={updateMethodsWrapper}
                        memberType="dimensions"
                      />
                    </Form.Item>
                  </Form>
                </>
              )}
            </Col>
          </Row>
          {tmpAvailableMeasures && tmpAvailableMeasures.length > 0 && (
            <Row type="flex" justify="start">
              <Col span={10}>
                <Divider
                  style={{
                    marginTop: 10,
                    marginBottom: 10
                  }}
                />
              </Col>
            </Row>
          )}
          <Row
            type="flex"
            justify="space-around"
            align="top"
            gutter={24}
            style={{
              marginBottom: 12
            }}
          >
            <Col span={24}>
              {tmpAvailableSegments && tmpAvailableSegments.length > 0 && (
                <>
                  <MemberGroup members={segments} availableMembers={tmpAvailableSegments} addMemberName="Сегменты" updateMethods={updateSegments} />
                </>
              )}

              {tmpAvailableTimeDimensions && tmpAvailableTimeDimensions.length > 0 && (
                <>
                  <Divider type="vertical" />

                  <TimeGroup members={timeDimensions} availableMembers={tmpAvailableTimeDimensions} addMemberName="Время" updateMethods={updateTimeDimensions} />
                </>
              )}
            </Col>
          </Row>

          {tmpAvailableMeasures && tmpAvailableDimensions && tmpAvailableMeasures.length > 0 && tmpAvailableDimensions.length > 0 && (
            <Row
              type="flex"
              justify="space-around"
              align="top"
              gutter={24}
              style={{
                marginBottom: 12
              }}
            >
              <Col span={24}>
                <FilterGroup
                  members={filters}
                  availableMembers={tmpAvailableDimensions ? tmpAvailableDimensions.concat(tmpAvailableMeasures) : []}
                  addMemberName="Фильтры"
                  updateMethods={updateFilters}
                  pivotConfig={pivotConfig}
                />
              </Col>
            </Row>
          )}

          <Row type="flex" justify="space-around" align="top" gutter={24}>
            <Col span={24}>
              <SelectChartType chartType={chartType} updateChartType={updateChartType} />

              <Divider type="vertical" />

              <Popover
                content={<OrderGroup orderMembers={orderMembers} onReorder={updateOrder.reorder} onOrderChange={updateOrder.set} />}
                placement="bottomLeft"
                trigger="click"
              >
                <Button disabled={!isQueryPresent} icon={<SortAscendingOutlined />}>
                  Сортировка
                </Button>
              </Popover>

              <Divider type="vertical" />

              <Popover
                content={<Pivot pivotConfig={pivotConfig} pivotFields={tmpAvailableDimensions.concat(tmpAvailableMeasures)} onMove={updatePivotConfig.moveItem} onUpdate={updatePivotConfig.update} />}
                placement="bottomLeft"
                trigger="click"
              >
                <Button disabled={!isQueryPresent} icon={<BorderInnerOutlined />}>
                  Транспонирование данных
                </Button>
              </Popover>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-around"
            align="top"
            gutter={24}
            style={{
              marginBottom: 0,
              marginTop: 10
            }}
          >
            <Col span={24}>
              <Form {...formItemLayout}>
                <Form.Item
                  label="Показывать строк:"
                  style={{
                    marginBottom: 0
                  }}
                >
                  <LimitSelect value={rateLimit} setRateLimit={setRateLimit} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ControlBar;