import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Select } from 'antd';
import { Icon } from '@ant-design/compatible';
import MemberDropdown from './MemberDropdown';
import RemoveButtonGroup from './RemoveButtonGroup';
import FilterInput from './FilterInput';
import { useCubeQuery } from '@cubejs-client/react';

const FilterGroup = ({ members, availableMembers, addMemberName, updateMethods, pivotConfig }) => {
    const [searchData, setSearchData] = useState({});
    const { resultSet: result } = useCubeQuery({
        dimensions: members.map((m) => m.member)
    });

    useEffect(() => {
        const _dimensions = members.map((m) => m.member);
        // let processedResult = result ? result.tablePivot(pivotConfig) : []
        const processedResult = result ? result.tablePivot() : []

        let search = {};
        for (const d of _dimensions) {
            if (!search[d]) search[d] = []
        }

        if (processedResult && processedResult.length > 0) {
            for (const row of processedResult) {
                for (let _d in row) {
                    if (search[_d] && search[_d].includes(row[_d])) continue;
                    if (search[_d]) search[_d].push(row[_d])
                }
            }
        }

        setSearchData(search);

    }, [result]);

    return (
        <span>
            {members.map((m, index) => (
                <div
                    style={{
                        marginBottom: 12
                    }}
                    key={m + index}
                >
                    <RemoveButtonGroup onRemoveClick={() => {
                        updateMethods.remove(m)
                    }}>
                        <MemberDropdown
                            onClick={(updateWith) => {
                                updateMethods.update(m, { ...m, dimension: updateWith });
                            }}
                            availableMembers={availableMembers}
                            style={{
                                minWidth: 150,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                textAlign: 'left'
                            }}
                        >
                            {m.dimension.shortTitle}
                        </MemberDropdown>
                    </RemoveButtonGroup>
                    <Select
                        value={m.operator}
                        onChange={(operator) => {
                            updateMethods.update(m, { ...m, operator });
                        }}
                        style={{
                            width: 200,
                            marginRight: 8
                        }}
                    >
                        {m.operators.map((operator, index) => (
                            <Select.Option key={operator.name + index} value={operator.name}>
                                {operator.title}
                            </Select.Option>
                        ))}
                    </Select>
                    <FilterInput member={m} key="filterInput" updateMethods={updateMethods} searchData={searchData} />
                </div>
            ))}
            <MemberDropdown
                onClick={(m) => {
                    updateMethods.add({
                        dimension: m
                    });
                }}
                availableMembers={availableMembers}
                type="dashed"
                icon={<Icon type="plus" />}
            >
                {addMemberName}
            </MemberDropdown>
        </span>
    );
};

FilterGroup.propTypes = {
    members: PropTypes.array.isRequired,
    availableMembers: PropTypes.array.isRequired,
    addMemberName: PropTypes.string.isRequired,
    updateMethods: PropTypes.object.isRequired,
    pivotConfig: PropTypes.object.isRequired,
};
export default FilterGroup;
