import Keycloak from 'keycloak-js';

export let initOptions = {
  url: 'https://auth.miccedu.ru/auth/', 
  redirectUri: window.location.origin,
  realm: 'givc', 
  clientId: 'olap-frontend', 
  onLoad: 'login-required'
}

export let keycloak = new Keycloak(initOptions);